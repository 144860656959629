import React, {Fragment, useState} from 'react'
import {fade, makeStyles} from '@material-ui/core/styles'
import {Link} from 'gatsby'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Badge from '@material-ui/core/Badge'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Fade from '@material-ui/core/Fade'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import MenuIcon from '@material-ui/icons/Menu'
import {useShoppingCart} from '../context'

import logo from '../../static/signxperts-logo.png'
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'block !important',
        [theme.breakpoints.up('md')]: {
            display: 'none !important',
        },
    },
    logo: {
        width: 180,
        [theme.breakpoints.up('md')]: {
            width: 250,
        },
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const Header = ({data}) => {
  // const setting = data.gcms.setting
    const classes = useStyles()
    const { cartCount } = useShoppingCart()

    const [openMenu, setOpenMenu] = useState(false)
    const [state, setState] = useState({
        anchorEl: {},
        open: {},
        openMobile: {},
        placement: {}
    });

    const MenuItems = [{
        heading: 'Banners',
        items: [{
            label: 'Vinyl Banners',
            link: '/banners/vinyl-banners'
        }, {
            label: 'Mesh Banners',
            link: '/banners/mesh-banners'
        }, {
            label: 'Fabric Banners',
            link: '/banners/fabric-banners'
        }, {
            label: 'Pole Banners',
            link: '/banners/pole-banners'
        }, {
            label: 'Step & Repeat Banners',
            link: '/banners/step-and-repeat-banners'
        }, {
            label: 'Standard Retractable Banners',
            link: '/banners/standard-retractable-banners'
        }, {
            label: 'Premium Retractable Banners',
            link: '/banners/premium-retractable-banners'
        }, {
            label: 'Deluxe Retractable Banners',
            link: '/banners/deluxe-retractable-banners'
        }, {
            label: 'Table Top Retractable Banners',
            link: '/banners/table-top-retractable-banners'
        }]
    }, {
        heading: 'Rigid Signs',
        items: [{
            label: 'Aluminum Signs',
            link: '/rigid-signs/aluminum-signs'
        }, {
            label: 'Brushed Aluminum Signs',
            link: '/rigid-signs/brushed-aluminum-signs'
        }, {
            label: 'Reflective Aluminum Signs',
            link: '/rigid-signs/reflective-aluminum-signs'
        }, {
            label: 'Yard Signs',
            link: '/rigid-signs/yard-signs'
        }, {
            label: 'Plastic Signs',
            link: '/rigid-signs/plastic-signs'
        }, {
            label: 'Acrylic Signs',
            link: '/rigid-signs/acrylic-signs'
        }, {
            label: 'Frosted Acrylic Signs',
            link: '/rigid-signs/frosted-acrylic-signs'
        }, {
            label: 'Foam Boards',
            link: '/rigid-signs/foam-board-signs'
        }, {
            label: 'A-Frames',
            link: '/rigid-signs/plastic-simpo-a-frame-signs'
        }, {
            label: 'Wooden Signs',
            link: '/rigid-signs/printed-wooden-signs'
        }, {
            label: 'Posters',
            link: '/rigid-signs/posters'
        }, {
            label: 'Car Magnets',
            link: '/rigid-signs/car-magnets'
        }, {
            label: 'Canvas Prints',
            link: '/rigid-signs/canvas-prints'
        }]
    }, {
        heading: 'Flags',
        items: [{
            label: 'Feather Flag Banners',
            link: '/flags/feather-flag-banners'
        }, {
            label: 'Teardrop Flag Banners',
            link: '/flags/teardrop-flag-banners'
        }, {
            label: 'Angled Flag Banners',
            link: '/flags/angled-flag-banners'
        }, {
            label: 'Rectangle Flag Banners',
            link: '/flags/rectangle-flag-banners'
        }]
    }, {
        heading: 'Decals',
        items: [{
            label: 'Repositionable Wall Decals',
            link: '/decals/wall-decals'
        }, {
            label: 'Permanent Decals',
            link: '/decals/permanent-decals'
        }, {
            label: 'Perforated Window Decals',
            link: '/decals/perforated-window-decals'
        }, {
            label: 'Clear Window Decals',
            link: '/decals/clear-window-decals'
        }, {
            label: 'Opaque Window Decals',
            link: '/decals/opaque-window-decals'
        }, {
            label: 'Clear Window Clings',
            link: '/decals/clear-window-clings'
        }, {
            label: 'Opaque Window Clings',
            link: '/decals/opaque-window-clings'
        }, {
            label: 'Floor Decals',
            link: '/decals/floor-decals'
        }, {
            label: 'Backlit',
            link: '/decals/backlit-decals'
        }]
    }, {
        heading: 'Events & Tradeshows',
        items: [{
            label: 'Tension Fabric Displays',
            link: '/events-and-tradeshows/tension-fabric-displays'
        }, {
            label: 'Tension Fabric Stands',
            link: '/events-and-tradeshows/tension-fabric-stands'
        }, {
            label: 'Pop-Up Tents',
            link: '/events-and-tradeshows/pop-up-tents'
        }, {
            label: 'Pop-Up Displays',
            link: '/events-and-tradeshows/pop-up-displays'
        }, {
            label: 'Curved Tension Pop-Up Displays',
            link: '/events-and-tradeshows/curved-tension-pop-up-displays'
        }, {
            label: 'Table Top Retractable Banners',
            link: '/events-and-tradeshows/table-top-retractable-banners'
        }]
    }, {
        heading: 'COVID-19',
        items: [{
            label: 'Posters',
            link: '/covid-19/posters'
        }, {
            label: 'Yard Signs',
            link: '/covid-19/yard-signs'
        }, {
            label: 'Vinyl Banners',
            link: '/covid-19/vinyl-banners'
        }, {
            label: 'Floor Decals',
            link: '/covid-19/floor-decals'
        }, {
            label: 'Counter Top Sneeze Guard',
            link: '/covid-19/counter-top-sneeze-guard'
        }]
    }]

    const handleClick = (placement, index) => event => {     // i renamed the second param to index
        console.log(index)
        const { currentTarget } = event
        setState(state => ({
            anchorEl: {
                [index]: currentTarget, // only change this one
            },
            open: {
                [index]: !state.open[index], // toggle only this one
            },
            openMobile: {
                [index]: !state.openMobile[index], // toggle only this one
            },
            placement: {
                [index]: placement, // update placement for this one
            },
        }))
    }

    const handleMobileClick = (placement, index) => event => {     // i renamed the second param to index
        const { currentTarget } = event
        setState(state => ({
            anchorEl: {
                [index]: currentTarget, // only change this one
            },
            open: state.open,
            openMobile: {
                [index]: !state.openMobile[index], // toggle only this one
            },
            placement: {
                [index]: placement, // update placement for this one
            },
        }))
    }

    const handleMenuClick = () => {
        setOpenMenu(!openMenu)
    };

    const handleCl = () => {
        // if (anchorRef.current && anchorRef.current.contains(event.target)) {
        //     return;
        // }
        setState(() => ({
            anchorEl: {},
            open: {},
            openMobile: {},
            placement: {},
        }))
    };

  return (
      <Fragment>
        <AppBar position="static" style={{backgroundColor: '#FFF'}}>
          <Toolbar>
              <Link to={'/'}>
                  <img src={logo} className={classes.logo} alt={'Signxperts Logo'}/>
              </Link>
              <div className={classes.grow}/>
              <Typography className={classes.sectionDesktop} style={{color: '#000'}}>
                  For custom orders, please contact&nbsp;<a href="mailto:info@signxperts.com">info@signxperts.com</a>
              </Typography>
              <div className={classes.grow}/>
              <IconButton aria-label="show 17 new notifications" color="default" href='/cart' style={{color: '#707070'}}>
                  <Badge badgeContent={cartCount} color="secondary">
                      <ShoppingCartIcon />
                  </Badge>
              </IconButton>
              <IconButton className={classes.sectionMobile} color="default" aria-label="menu" onClick={handleMenuClick}  style={{color: '#707070'}}>
                  {openMenu ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
          </Toolbar>
      </AppBar>
          <ClickAwayListener onClickAway={handleCl}>
              <Fragment>
                  <AppBar position="static" className={classes.sectionDesktop} style={{backgroundColor: '#FFF', borderBottom: 'solid 1px #ddd', borderTop: 'solid 1px #ddd'}} elevation={0}>
                      <Toolbar style={{justifyContent: 'space-evenly'}}>
                          { /* Some more content here... */ }
                          <Button
                              aria-controls={state.open[0] ? 'menu-list-grow' : undefined}
                              aria-haspopup="true"
                              variant={state.open[0] ? 'contained' : undefined}
                              color={state.open[0] ? 'secondary' : undefined}
                              disableRipple
                              onClick={handleClick('bottom', 0)}
                          >
                              Banners
                          </Button>
                          <Popper
                              open={state.open[0] || false}
                              anchorEl={state.anchorEl[0] || null}
                              transition
                              style={{width: '100%', zIndex: 2}}>
                              {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={0}>
                                      <Paper style={{maxHeight: 350, overflowY: 'auto', padding: 16}}>
                                          <Grid container>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Banners</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/banners/vinyl-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Vinyl Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/banners/mesh-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Mesh Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/banners/fabric-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Fabric Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/banners/pole-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Pole Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Banner Stands</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/banners/step-and-repeat-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Step & Repeat Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/banners/standard-retractable-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Standard Retractable Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/banners/premium-retractable-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Premium Retractable Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/banners/deluxe-retractable-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Deluxe Retractable Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/banners/table-top-retractable-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Table Top Retractable Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}/>
                                              <Grid item sm={3}/>
                                              <Grid item sm={3}/>
                                          </Grid>
                                      </Paper>
                                  </Fade>
                              )}
                          </Popper>

                          <Button
                              aria-controls={state.open[1] ? 'menu-list-grow' : undefined}
                              aria-haspopup="true"
                              variant={state.open[1] ? 'contained' : undefined}
                              color={state.open[1] ? 'secondary' : undefined}
                              disableRipple
                              onClick={handleClick('bottom', 1)}
                          >
                              Rigid Signs
                          </Button>
                          <Popper
                              open={state.open[1] || false}
                              anchorEl={state.anchorEl[1] || null}
                              transition
                              style={{width: '100%', zIndex: 2}}
                          >
                              {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={0}>
                                      <Paper style={{maxHeight: 350, overflowY: 'auto', padding: 16}}>
                                          <Grid container>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Metal Signs</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/aluminum-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Aluminum Signs" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/brushed-aluminum-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Brushed Aluminum Signs" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/reflective-aluminum-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Reflective Aluminum Signs" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                                  <Box>
                                                      <Typography variant={'h6'}>Plastic Signs</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/yard-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Yard Signs" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/plastic-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Plastic Signs" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Additonal Signs</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/acrylic-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Acrylic" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/frosted-acrylic-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Frosted Acrylic" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/foam-board-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Foam Boards" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/plastic-simpo-a-frame-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="A-Frames" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/printed-wooden-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Wooden Signs" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/posters'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Posters" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Magnetic Signs</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/car-magnets'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Car Magnets" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Specialty Prints</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/rigid-signs/canvas-prints'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Canvas Prints" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                          </Grid>
                                      </Paper>
                                  </Fade>
                              )}
                          </Popper>

                          <Button
                              aria-controls={state.open[2] ? 'menu-list-grow' : undefined}
                              aria-haspopup="true"
                              variant={state.open[2] ? 'contained' : undefined}
                              color={state.open[2] ? 'secondary' : undefined}
                              disableRipple
                              onClick={handleClick('bottom', 2)}
                          >
                              Flags
                          </Button>
                          <Popper
                              open={state.open[2] || false}
                              anchorEl={state.anchorEl[2] || null}
                              transition
                              style={{width: '100%', zIndex: 2}}
                          >
                              {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={0}>
                                      <Paper style={{maxHeight: 350, overflowY: 'auto', padding: 16}}>
                                          <Grid container>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Advertising Flags</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/flags/feather-flag-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Feather Flag Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/flags/teardrop-flag-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Teardrop Flag Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/flags/angled-flag-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Angled Flag Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/flags/rectangle-flag-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Rectangle Flag Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3} />
                                              <Grid item sm={3} />
                                              <Grid item sm={3} />
                                          </Grid>
                                      </Paper>
                                  </Fade>
                              )}
                          </Popper>

                          <Button
                              aria-controls={state.open[3] ? 'menu-list-grow' : undefined}
                              aria-haspopup="true"
                              variant={state.open[3] ? 'contained' : undefined}
                              color={state.open[3] ? 'secondary' : undefined}
                              disableRipple
                              onClick={handleClick('bottom', 3)}
                          >
                              Decals
                          </Button>
                          <Popper
                              open={state.open[3] || false}
                              anchorEl={state.anchorEl[3] || null}
                              transition
                              style={{width: '100%', zIndex: 2}}
                          >
                              {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={0}>
                                      <Paper style={{maxHeight: 350, overflowY: 'auto', padding: 16}}>
                                          <Grid container>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Wall Decals</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/decals/wall-decals'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Repositionable Wall Decals" />
                                                              </Link>
                                                          </MenuItem>
                                                          {/*
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/vinyl-lettering'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Vinyl Lettering" />
                                                      </Link>
                                                  </MenuItem>
                                                  */}
                                                          <MenuItem disableRipple>
                                                              <Link to={'/decals/permanent-decals'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Permanent Decals" />
                                                              </Link>
                                                          </MenuItem>
                                                          {/*
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/outdoor-wall-decals'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Outdoor Wall Decals" />
                                                      </Link>
                                                  </MenuItem>
                                                  */}
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Window Decals</Typography>
                                                      <MenuList>
                                                          {/*
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/vinyl-lettering'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Vinyl Lettering" />
                                                      </Link>
                                                  </MenuItem>
                                                  */}
                                                          <MenuItem disableRipple>
                                                              <Link to={'/decals/perforated-window-decals'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Perforated Window Decals" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/decals/clear-window-decals'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Clear Window Decals" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/decals/opaque-window-decals'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Opaque Window Decals" />
                                                              </Link>
                                                          </MenuItem>
                                                          {/*
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/frosted-window-decals'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Frosted Window Decals" />
                                                      </Link>
                                                  </MenuItem>
                                                  */}
                                                      </MenuList>
                                                  </Box>
                                                  <Box>
                                                      <Typography variant={'h6'}>Window Clings</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/decals/clear-window-clings'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Clear Window Clings" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/decals/opaque-window-clings'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Opaque Window Clings" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Floor Decals</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/decals/floor-decals'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Floor Decals" />
                                                              </Link>
                                                          </MenuItem>
                                                          {/*
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/carpet-stickers'} style={{textDecoration: 'none'}}>
                                                          <ListItemText primary="Carpet Stickers" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/street-and-sidewalk-decals'} style={{textDecoration: 'none'}}>
                                                          <ListItemText primary="Street & Sidewalk Decals" />
                                                      </Link>
                                                  </MenuItem>
                                                  */}
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Others</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/decals/backlit-decals'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Backlit" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                                  {/*
                                          <Box>
                                              <Typography variant={'h6'}>Vehicle Decals</Typography>
                                              <MenuList>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/clear-vehicle-decals'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Clear Vehicle Decals" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/car-and-truck-lettering'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Car & Truck Lettering" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/opaque-vehicle-decals'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Opaque Vehicle Decals" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/perforated-vehicle-decals'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Perforated Vehicle Decals" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/vehicle-magnets'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Vehicle Magnets" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/fleet-lettering'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Fleet Lettering" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/fleet-graphics'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Fleet Graphics" />
                                                      </Link>
                                                  </MenuItem>
                                              </MenuList>
                                          </Box>
                                          <Box>
                                              <Typography variant={'h6'}>Boat Decals</Typography>
                                              <MenuList>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/boat-decals'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Boat Decals" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/decals/boat-lettering'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Boat Lettering" />
                                                      </Link>
                                                  </MenuItem>
                                              </MenuList>
                                          </Box>
                                          */}
                                              </Grid>
                                          </Grid>
                                      </Paper>
                                  </Fade>
                              )}
                          </Popper>

                          <Button
                              aria-controls={state.open[4] ? 'menu-list-grow' : undefined}
                              aria-haspopup="true"
                              variant={state.open[4] ? 'contained' : undefined}
                              color={state.open[4] ? 'secondary' : undefined}
                              disableRipple
                              onClick={handleClick('bottom', 4)}
                          >
                              Events & Tradeshows
                          </Button>
                          <Popper
                              open={state.open[4] || false}
                              anchorEl={state.anchorEl[4] || null}
                              transition
                              style={{width: '100%', zIndex: 2}}
                          >
                              {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={0}>
                                      <Paper style={{height: 350, overflowY: 'auto', padding: 16}}>
                                          <Grid container>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Displays & Stands</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/events-and-tradeshows/tension-fabric-displays'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Tension Fabric Displays" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/events-and-tradeshows/tension-fabric-stands'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Tension Fabric Stands" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/events-and-tradeshows/pop-up-tents'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Pop-Up Tents" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/events-and-tradeshows/pop-up-displays'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Pop-Up Displays" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/events-and-tradeshows/curved-tension-pop-up-displays'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Curved Tension Pop-Up Displays" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>Table</Typography>
                                                      <MenuList>
                                                          {/*
                                                  <MenuItem disableRipple>
                                                      <Link to={'/events-and-tradeshows/table-covers'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Table Covers" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/events-and-tradeshows/table-runners'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Table Runners" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/events-and-tradeshows/stretch-table-covers'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Stretch Table Covers" />
                                                      </Link>
                                                  </MenuItem>
                                                  */}
                                                          <MenuItem disableRipple>
                                                              <Link to={'/events-and-tradeshows/table-top-retractable-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Table Top Retractable Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}/>
                                              <Grid item sm={3}/>
                                          </Grid>
                                      </Paper>
                                  </Fade>
                              )}
                          </Popper>

                          {/*
                  <Button
                      aria-controls={state.open[5] ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      variant={state.open[5] ? 'contained' : undefined}
                      color={state.open[5] ? 'secondary' : undefined}
                      disableRipple
                      onClick={handleClick('bottom', 5)}
                  >
                      Small Format
                  </Button>
                  <Popper
                      open={state.open[5] || false}
                      anchorEl={state.anchorEl[5] || null}
                      transition
                      style={{width: '100%', zIndex: 2}}
                  >
                      {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={0}>
                              <Paper style={{height: 350, overflowY: 'auto', padding: 16}}>
                                  <Grid container>
                                      <Grid item sm={3}>
                                          <Box>
                                              <Typography variant={'h6'}>Small Format</Typography>
                                              <MenuList>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/small-format/brochures'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Brochures" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/small-format/table-tents'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Table Tents" />
                                                      </Link>
                                                  </MenuItem>
                                              </MenuList>
                                          </Box>
                                      </Grid>
                                      <Grid item sm={3} />
                                      <Grid item sm={3} />
                                      <Grid item sm={3} />
                                  </Grid>
                              </Paper>
                          </Fade>
                      )}
                  </Popper>
                  */}

                          {/*
                  <Button
                      aria-controls={state.open[6] ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      variant={state.open[6] ? 'contained' : undefined}
                      color={state.open[6] ? 'secondary' : undefined}
                      disableRipple
                      onClick={handleClick('bottom', 6)}
                  >
                      Signs By Use
                  </Button>
                  <Popper
                      open={state.open[6] || false}
                      anchorEl={state.anchorEl[6] || null}
                      transition
                      style={{width: '100%', zIndex: 2}}
                  >
                      {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={0}>
                              <Paper style={{height: 350, overflowY: 'auto', padding: 16}}>
                                  <Grid container>
                                      <Grid item sm={3}>
                                          <Box>
                                              <Typography variant={'h6'}>Business Signs</Typography>
                                              <MenuList>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/retail-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Retail Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/restaurant-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Restaurant Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/trade-show-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Trade Show Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/business-hours-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Business Hours Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/now-hiring-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Now Hiring Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/reserved-parking-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Reserved Parking Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/coming-soon-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Coming Soon Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/grand-opening-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Grand Opening Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/no-smoking-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="No Smoking Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/employees-only-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Employees Only Signs" />
                                                      </Link>
                                                  </MenuItem>
                                              </MenuList>
                                          </Box>
                                      </Grid>
                                      <Grid item sm={3}>
                                          <Box>
                                              <Typography variant={'h6'}>Real Estate Signs</Typography>
                                              <MenuList>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/real-estate-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Real Estate Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/real-estate-rider'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Real Estate Rider" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/real-estate-frames'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Real Estate Frames" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/open-house-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Open House Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/for-lease-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="For Lease Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/for-rent-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="For Rent Signs" />
                                                      </Link>
                                                  </MenuItem>
                                              </MenuList>
                                          </Box>
                                      </Grid>
                                      <Grid item sm={3}>
                                          <Box>
                                              <Typography variant={'h6'}>Other</Typography>
                                              <MenuList>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/campaign-and-political-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Campaign & Political Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/directional-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Directional Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/graduation-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Graduation Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/religious-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Church/Religious Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/construction-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Construction Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/happy-birthday-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Happy Birthday Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/trespassing-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="No Trespassing Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/pool-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Pool Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/no-smoking-signs'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="No Smoking Signs" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/a-frames'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="A-Frames" />
                                                      </Link>
                                                  </MenuItem>
                                                  <MenuItem disableRipple>
                                                      <Link to={'/signs-by-use/poster'} style={{textDecoration: 'none'}}>
                                                        <ListItemText primary="Poster" />
                                                      </Link>
                                                  </MenuItem>
                                              </MenuList>
                                          </Box>
                                      </Grid>
                                      <Grid item sm={3} />
                                  </Grid>
                              </Paper>
                          </Fade>
                      )}
                  </Popper>
                  */}

                          <Button
                              aria-controls={state.open[7] ? 'menu-list-grow' : undefined}
                              aria-haspopup="true"
                              variant={state.open[7] ? 'contained' : undefined}
                              color={state.open[7] ? 'secondary' : undefined}
                              disableRipple
                              onClick={handleClick('bottom', 7)}
                          >
                              COVID-19
                          </Button>
                          <Popper
                              open={state.open[7] || false}
                              anchorEl={state.anchorEl[7] || null}
                              transition
                              style={{width: '100%', zIndex: 2}}
                          >
                              {({ TransitionProps }) => (
                                  <Fade {...TransitionProps} timeout={0}>
                                      <Paper style={{height: 350, overflowY: 'auto', padding: 16}}>
                                          <Grid container>
                                              <Grid item sm={3}>
                                                  <Box>
                                                      <Typography variant={'h6'}>COVID-19 Signs</Typography>
                                                      <MenuList>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/covid-19/posters'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Posters" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/covid-19/yard-signs'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Yard Signs" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/covid-19/vinyl-banners'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Vinyl Banners" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/covid-19/floor-decals'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Floor Decals" />
                                                              </Link>
                                                          </MenuItem>
                                                          <MenuItem disableRipple>
                                                              <Link to={'/covid-19/counter-top-sneeze-guard'} style={{textDecoration: 'none'}}>
                                                                  <ListItemText primary="Counter Top Sneeze Guard" />
                                                              </Link>
                                                          </MenuItem>
                                                      </MenuList>
                                                  </Box>
                                              </Grid>
                                              <Grid item sm={3}/>
                                              <Grid item sm={3}/>
                                              <Grid item sm={3}/>
                                          </Grid>
                                      </Paper>
                                  </Fade>
                              )}
                          </Popper>
                      </Toolbar>
                  </AppBar>
                  <Collapse in={openMenu} timeout="auto" unmountOnExit>
                      <List
                          component="nav"
                          aria-labelledby="nested-list-subheader"
                          className={classes.root}
                      >
                          {MenuItems.map(({heading, items}, index) => (
                              <Fragment key={index}>
                                  <ListItem button onClick={handleMobileClick(null, index)} style={{borderBottom: 'solid', borderColor: '#CCC', borderWidth: 1}}>
                                      <ListItemText primary={heading} />
                                      {state.openMobile[index] || false ? <ExpandLess /> : <ExpandMore />}
                                  </ListItem>
                                  <Collapse in={state.openMobile[index] || false} timeout="auto" unmountOnExit>
                                      <List component="div" disablePadding>
                                          {items.map(({label, link}, i) => (
                                              <ListItem button key={i}>
                                                  <Link to={link} className={classes.nested} style={{textDecoration: 'none'}}>
                                                      <ListItemText primary={label} />
                                                  </Link>
                                              </ListItem>
                                          ))}
                                      </List>
                                  </Collapse>
                              </Fragment>
                          ))}
                      </List>
                  </Collapse>
              </Fragment>
          </ClickAwayListener>
      </Fragment>
  )
}

export default Header
